/* eslint-disable react-hooks/exhaustive-deps */
// TODO: add handle errors

import React, { useEffect, useState } from 'react';
import { ZoomMtg } from '@zoomus/websdk';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';

import './App.css';
import { useAutoRefreshToken } from './hooks/useAutoRefreshToken';
import axios from './axiosInstance';

const FORM_URL =
  'https://survey.survicate.com/e8a87cdeff4bedf4/?_svbep=P3A9YW5vbnltb3Vz';
const DOMAIN_REGEX = /[^.]*\.[^.]{2,3}(?:\.[^.]{2,3})?$/gm;

const setFeedbackFormUrlToCookie = (currentUser) => {
  // After this time, the cookie will expire one hour later.
  const oneHourFromNow = new Date();
  oneHourFromNow.setTime(oneHourFromNow.getTime() + 60 * 60 * 1000);

  if (currentUser.type === 'student') {
    // Get origin url from url path
    const originUrl = new URL(window.location.href).origin;

    // Get domain for cookie from origin url
    const domainUrl = originUrl.match(DOMAIN_REGEX);

    const domain = originUrl.includes('localhost')
      ? 'localhost'
      : `.${domainUrl[0]}`;

    Cookies.set('webinarEnded', FORM_URL, {
      domain,
      expires: oneHourFromNow,
    });
  };
};

// Start Zoom
const loadZoom = async (setIsLanguageSet) => {
  ZoomMtg.setZoomJSLib('https://source.zoom.us/2.18.0/lib', '/av');
  ZoomMtg.preLoadWasm();
  ZoomMtg.prepareWebSDK();

  await ZoomMtg.i18n.load(Cookies.get('lng') || 'en-US');
  ZoomMtg.i18n.reload(Cookies.get('lng') || 'en-US');

  setIsLanguageSet(true);
};

export const App = () => {
  const [webinarId, setWebinarId] = useState();
  const [isLanguageSet, setIsLanguageSet] = useState(false);
  const [currentUser, setCurrentUser] = useState({
    firstName: '',
    lastName: '',
    username: '',
    roles: [],
  });
  const [meetingInfo, setMeetingInfo] = useState({
    meetingNumber: '',
    signature: '',
    password: '',
  });
  const [isUserAuthenticated, setIsUserAuthenticated] = useState(true);
  const [leaveUrl, setLeaveUrl] = useState(process.env.REACT_APP_MAIN_URL);

  useAutoRefreshToken();

  // Get current user data
  const fetchCurrentUser = async (webinarId) => {
    const accessToken = Cookies.get(
      `${process.env.REACT_APP_STORAGE_KEY}:accessToken`
    );
    const decoded = jwtDecode(accessToken);

    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/users/${decoded.sub}`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );

      if (!resp.data.error) {
        const { data } = resp;

        setCurrentUser(data);

        const url =
          data?.roles.includes('tutor')
            ? `${process.env.REACT_APP_MAIN_URL}/${Cookies.get('lng')?.slice(0, 2) || 'id'}/my-webinars/${webinarId}`
            : `${process.env.REACT_APP_MAIN_URL}/${Cookies.get('lng')?.slice(0, 2) || 'id'}/webinars/${webinarId}`;

        setLeaveUrl(url);

        // get stored meeting info
        const stored = Cookies.get(
          `${process.env.REACT_APP_STORAGE_KEY}:zoomMeetingInfo`
        );

        if (stored) {
          const storedData = JSON.parse(stored)

          if (storedData.data) {
            setMeetingInfo(storedData.data.zoomus);
          } else {
            setMeetingInfo(storedData);
          }
        };

        if (data.firstName) {
          loadZoom(setIsLanguageSet);
        };

      } else {
        throw new Error('Failed to fetch user data');
      };
    } catch (error) {
      setIsUserAuthenticated(false);

      console.log(error);
    };
  };

  const startMeeting = () => {
    document.getElementById('zmmtg-root').style.display = 'block';

    ZoomMtg.init({
      leaveUrl,
      success: (success) => {
        const zak =
          currentUser.roles.includes('tutor')
            ? meetingInfo?.url
              ? new URL(meetingInfo.url).searchParams.get('zak')
              : meetingInfo.token
            : null;
        const tk =
          currentUser.roles.includes('student')
            ? meetingInfo?.url
              ? new URL(meetingInfo.url).searchParams.get('tk')
              : meetingInfo.token
            : null;

        ZoomMtg.join({
          sdkKey: process.env.REACT_APP_ZOOM_SDK,
          signature: meetingInfo.signature,
          meetingNumber: meetingInfo.meetingNumber,
          passWord: meetingInfo.password,
          userName: `${currentUser.firstName} ${currentUser.lastName}`,
          userEmail:
            currentUser.roles.includes('tutor')
              ? meetingInfo.hostEmail
              : currentUser.username,
          zak,
          tk,
          success: (success) => {
            setFeedbackFormUrlToCookie(currentUser);
          },
          error: (error) => {
            console.log(error);
          },
        });
      },
      error: (error) => {
        console.log(error);
      },
    });
  };

  // Get access token and appointment id from URL
  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const webinarId = query.get('webinarId');


    const refreshToken = Cookies.get(
      `${process.env.REACT_APP_STORAGE_KEY}:refreshToken`
    );

    setWebinarId(webinarId);

    if (refreshToken && webinarId) {
      fetchCurrentUser(webinarId);
      return;
    };

    setIsUserAuthenticated(false);
  }, []);

  useEffect(() => {
    if (isLanguageSet) {
      startMeeting();
    };
  }, [isLanguageSet]);

  return (
    <main className="App">
      {isUserAuthenticated ? (
        <>
          <h1 className="title">Your meeting will start now...</h1>
          <div id="meetingSDKElement" />
        </>
      ) : (
        <>
          <h1 className="title">You are not authenticated.</h1>
          <button className="btn" onClick={() => {
            window.location.href = `${process.env.REACT_APP_MAIN_URL}/webinars/${webinarId}`
          }}>Go to the webinar</button>
        </>
      )}
    </main>
  );
};
