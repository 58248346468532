import Cookies from 'js-cookie';
import { useEffect } from 'react';

const url = window.location.host

export const useAutoRefreshToken = () => {
  const refreshToken = Cookies.get(
    `${process.env.REACT_APP_STORAGE_KEY}:refreshToken`
  );

  useEffect(() => {
    const refreshTokenHandler = async () => {

      try {
        const res = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/auth/refresh`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${refreshToken}`,
          },
        });

        const data = await res.json()

        Cookies.set(
          `${process.env.REACT_APP_STORAGE_KEY}:accessToken`,
          data.accessToken,
          {
            domain: window.location.host.includes('localhost')
              ? 'localhost'
              : url.substring(url.indexOf('.')),
          }
        );
      } catch (error) {
        throw new Error(error);
      }
    };

    // refreshing access token each 2 minutes
    const timeout = setInterval(() => {
      if (refreshToken) {
        refreshTokenHandler();
      }
    }, 120000);

    return () => {
      clearInterval(timeout);
    };
  }, [refreshToken]);
};
