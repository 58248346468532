import axiosInstance from 'axios';
import axiosAuthRefresh from 'axios-auth-refresh';
import Cookies from 'js-cookie';

let isTokenValid = true;

const url = window.location.host

const refreshToken = Cookies.get(
  `${process.env.REACT_APP_STORAGE_KEY}:refreshToken`
);

const axios = axiosInstance.create({
  baseURL: 'your_api_base_url',
  headers: {
    'Content-Type': 'application/json',
  },
});

const refreshTokenHandler = () =>
  axios
    .post(
      `${process.env.REACT_APP_API_BASE_URL}/api/auth/refresh`,
      {},
      {
        headers: {
          Authorization: `Bearer ${refreshToken}`,
        },
      }
    )
    .then((res) => {
      const newAccessToken = res.data.accessToken;

      axios.defaults.headers.common.Authorization = `Bearer ${newAccessToken}`;

      Cookies.set(
        `${process.env.REACT_APP_STORAGE_KEY}:accessToken`,
        newAccessToken,
        {
          domain: window.location.host.includes('localhost')
            ? 'localhost'
            : url.substring(url.indexOf('.')),
        }
      );

      isTokenValid = true;

      return Promise.resolve();
    });

axios.interceptors.request.use(
  (config) => {
    const accessToken = Cookies.get(
      `${process.env.REACT_APP_STORAGE_KEY}:accessToken`
    );

    config.headers.Authorization = `Bearer ${isTokenValid ? accessToken : refreshToken}`;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      isTokenValid = false;
    }
    return Promise.reject(error);
  }
);

axiosAuthRefresh(axios, refreshTokenHandler);

export default axios